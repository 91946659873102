import React from 'react'
import './Portfolio.css'
import { useState } from 'react';
import bed1 from './Portfolio-image/bed1.jpg';
import bed2 from './Portfolio-image/bed2.webp';
import bed3 from './Portfolio-image/bed3.jpg';
import bed4 from './Portfolio-image/bed4.jpg';
import kitchen1 from './Portfolio-image/kitchen1.jpg';
import kitchen2 from './Portfolio-image/kitchen2.avif';
import kitchen3 from './Portfolio-image/kitchen3.jpg';
import kitchen4 from './Portfolio-image/kitchen4.webp';
import living1 from './Portfolio-image/living1.png';
import living2 from './Portfolio-image/living2.jpg';
import living3 from './Portfolio-image/living3.jpg';
import living4 from './Portfolio-image/living4.webp';
import progress1 from './Portfolio-image/progress1.jpg';
import progress2 from './Portfolio-image/progress2.avif';
import progress3 from './Portfolio-image/progress3.jpg';
import progress4 from './Portfolio-image/progress4.jpg';
import progress5 from './Portfolio-image/progress41.jpg';
import progress6 from './Portfolio-image/progress42.jfif';
import progress7 from './Portfolio-image/progress43.jfif';
import progress8 from './Portfolio-image/progress44.jfif';
import progress9 from './Portfolio-image/progress45.jfif';
import progress10 from './Portfolio-image/progress46.jfif';


import WhatappBed1 from './Portfolio-image/WhatappBed1.jpg';
import WhatappBed2 from './Portfolio-image/WhatappBed2.jpg';
import WhatappBed3 from './Portfolio-image/WhatappBed3.jpg';
import WhatappBed4 from './Portfolio-image/WhatappBed4.jpg';
import WhatappKitchen1 from './Portfolio-image/WhatappKitchen1.jpg';
import WhatappKitchen2 from './Portfolio-image/WhatappKitchen2.jpg';
import WhatappKitchen3 from './Portfolio-image/WhatappKitchen3.jpg';

const sections = [
    {
        name: 'Bed Room',
        images: [
            bed1 ,bed2, bed3, bed4,
            WhatappBed1, WhatappBed2, WhatappBed3, WhatappBed4,
        ],
    },
    {
        name: 'Modular Kitchen',
        images: [
            kitchen1 ,kitchen2, kitchen3, kitchen4,
            WhatappKitchen1, WhatappKitchen2, WhatappKitchen3,
        ],    
    },
    {
        name: 'Living Room',
        images: [
            living1 ,living2, living3, living4,
        ],
    },
    {
        name: 'In Progress',
        images: [
            progress1 ,progress2, progress3, progress4, progress5, progress6,progress7,progress8,progress9,progress10,
        ],
    
    },
];

const Portfolio = () => {

    const [selectedSection, setSelectedSection] = useState(null);


    return (
        <div className='portfolio-container'>
            <div className="portfolio-page">
                <div className="portfolio-header">
                    <img
                        src="https://png.pngtree.com/thumb_back/fh260/background/20230704/pngtree-contemporary-beige-home-interior-featuring-chic-modern-furniture-3d-render-image_3756970.jpg"
                        alt="Portfolio"
                        className="header-image"
                    />
                    <div className="header-text">Portfolio </div>
                </div>
                <div className="section-menu">
                    {sections.map((section, index) => (
                        <div
                            key={index}
                            className="section-item"
                            onClick={() => setSelectedSection(section)}
                        >
                            {section.name}
                        </div>
                    ))}
                </div>
                <div className="image-grid">
                    {selectedSection &&
                        selectedSection.images.map((image, index) => (
                            <div key={index} className="image-item">
                                <img src={image} alt={`${selectedSection.name} ${index + 1}`} />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default Portfolio;