
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Componenets/Home/Home';
import About from './Componenets/About/About';
import Contact from './Componenets/Contact/Contact';
import Navbar from './Componenets/Navbar/Navbar';
import Footer from './Componenets/Footer/Footer';
import Client from './Componenets/Client/Client';
import Portfolio from './Componenets/Portfolio/Portfolio';
import Services from './Componenets/Services/Services';


function App() {
  return (

    <Router>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/about" element={<About />}/>
          <Route path="/portfolio" element={<Portfolio />}/>
          <Route path="/client" element={<Client/>}/>
          <Route path="/services" element={<Services/>}/>
          <Route path="/contact" element={<Contact/>}/>
        </Routes>
        <Footer/>
    </Router>
  );
}

export default App;
