import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slide1 from './Home-image/Slide1.jpg';
import Slide2 from './Home-image/Slide2.jpeg';
import Slide3 from './Home-image/Slide3.jpg';
import Slide4 from './Home-image/Slide4.jpeg';
import Slide5 from './Home-image/Slide5.jpg';

import Home1 from './Home-image/Home1.jpg';
import Home2 from './Home-image/Home2.jpg';
import Home3 from './Home-image/Home3.jpg';

import Chair from './Home-image/Chair.jpg';
import Cupboard from './Home-image/cupboard.jpg';
import Table from './Home-image/table.webp';
import Board from './Home-image/bord.webp';
import hanging from './Home-image/hanging.jpg';
import Bed from './Home-image/Bed.jpg';
import Sofa from './Home-image/Sofa.jpg';
import Study from './Home-image/Study.jpg';

import interior1 from './Home-image/interior1.jpg';
import interior2 from './Home-image/interior2.avif';
import interior3 from './Home-image/interior3.jpg';
import interior4 from './Home-image/interior4.jpg';
import interior5 from './Home-image/interior5.jpg';
import interior6 from './Home-image/interior6.jpg';
import interior7 from './Home-image/interior7.jpg';
import interior8 from './Home-image/interior8.jpg';

import review1 from './Home-image/review1.jpg';
import review2 from './Home-image/review2.jpeg';
import review3 from './Home-image/review3.webp';
import review4 from './Home-image/review4.webp';
import review5 from './Home-image/review5.jpg';
import review6 from './Home-image/review6.jpg';

import { FaKitchenSet } from "react-icons/fa6";
import { FaWarehouse } from "react-icons/fa";
import { FaCouch } from "react-icons/fa";
import { FaTv } from "react-icons/fa";
import { FaTable } from "react-icons/fa";
import { FaPaintRoller } from "react-icons/fa";
import { FaLightbulb } from "react-icons/fa";
import { FaToilet } from "react-icons/fa";
import { FaDoorClosed } from "react-icons/fa";
import { FaSink } from "react-icons/fa";
import { FaBlind } from "react-icons/fa";
import { FaFireExtinguisher } from "react-icons/fa6";
import { FaChair } from "react-icons/fa";
import { FaBed } from "react-icons/fa";


import './Home.css';

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const imageStyle = {
    maxHeight: '500px',
    objectFit: 'cover',
  };


  const images = [
    { src: interior1, text: 'Image 1' },
    { src: interior2, text: 'Image 2' },
    { src: interior3, text: 'Image 3' },
    { src: interior4, text: 'Image 4' },
    { src: interior5, text: 'Image 5' },
    { src: interior6, text: 'Image 6' },
    { src: interior7, text: 'Image 7' },
    { src: interior8, text: 'Image 8' },
  ];


  const services = [
    { icon: <FaKitchenSet />, name: 'Modular Kitchen' },
    { icon: <FaWarehouse />, name: 'Storage' },
    { icon: <FaCouch />, name: 'Space Saving Furniture' },
    { icon: <FaTv />, name: 'TV Unit' },
    { icon: <FaTable />, name: 'Study Table' },
    { icon: <FaPaintRoller />, name: 'False Ceiling' },
    { icon: <FaLightbulb />, name: 'Lights' },
    { icon: <FaToilet />, name: 'Bathroom' },
    { icon: <FaDoorClosed />, name: 'Door' },
    { icon: <FaSink />, name: 'Sink' },
    { icon: <FaBlind />, name: 'Blind' },
    { icon: <FaFireExtinguisher />, name: 'Fire Extinguisher' },
    { icon: <FaChair />, name: 'Chair' },
    { icon: <FaBed />, name: 'Sleeping Bed' },

  ];

  const cards = [
    {
      image: hanging,
      title: 'Hanging Sofa',
      description: 'Elevate your space with our AirLuxe Hanging Sofa, blending modern style with plush comfort for indoor and outdoor relaxation.',
    },
    {
      image: Bed,
      title: 'DreamScape Bed',
      description: 'Discover serenity with our DreamScape Bed, crafted for luxurious comfort and peaceful sleep, blending timeless design with modern elegance.',
    },
    {
      image: Study,
      title: 'Studious Storage Cabinet',
      description: 'Organize with style using our Studious Storage Cabinet, designed for efficient storage and sleek, modern appeal in any study or workspace.',
    },
    {
      image: Sofa,
      title: 'ComfortBlend Sofa',
      description: 'Experience luxurious comfort with our ComfortBlend Sofa, crafted for style and relaxation, perfect for any contemporary living room.',
    },
    {
      image: Cupboard,
      title: 'SleekStorage Cupboard',
      description: 'Maximize space with our SleekStorage Cupboard, offering stylish organization and modern design for any room.',
    },
    {
      image: Board,
      title: 'Visionary Board',
      description: 'Explore creativity with our Visionary Board, a versatile and essential tool for brainstorming and organizing ideas.',
    },
    {
      image: Table,
      title: 'Table',
      description: 'Discover versatility with our VersaTable, designed for functionality and style, perfect for any space from dining rooms to offices.',
    },
    {
      image: Chair,
      title: 'ErgoFlex Chair',
      description: 'Experience comfort and support with our ErgoFlex Chair, designed for ergonomic excellence and style, ideal for home offices or workspaces.',
    },
  ];


  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: 'What is your return policy?',
      answer: 'Our return policy allows returns within 30 days of purchase with a receipt.',
    },
    {
      question: 'How do I track my order?',
      answer: 'You can track your order using the tracking number provided in your confirmation email.',
    },
    {
      question: 'Do you offer international shipping?',
      answer: 'Yes, we offer international shipping to many countries. Please check our shipping policy for more details.',
    },
    {
      question: 'How can I contact customer service?',
      answer: 'You can contact customer service via email at support@example.com or call us at 1-800-123-4567.',
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };


  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
  };

  const reviews = [
    { image: review1, name: 'Tushar shelake', message: 'Great service!', stars: 5 },
    { image: review2, name: 'Ganesh Nimbalkar', message: 'Loved the experience!', stars: 4 },
    { image: review3, name: 'Hanumant Hargude', message: 'Highly recommended!', stars: 5 },
    { image: review4, name: 'Suvarna Sasvade', message: 'Great service!', stars: 5 },
    { image: review5, name: 'Vinayak Khair', message: 'Loved the experience!', stars: 4 },
    { image: review6, name: 'Rahul Mane', message: 'Highly recommended!', stars: 5 },
    // Add more reviews as needed
  ];



  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  // };

  // const imageStyle = {
  //   maxHeight: '500px',
  //   objectFit: 'cover',
  // };



  return (
    <div className="home-container">
      <div className="slider-container">
        <Slider {...settings}>
          <div>
            <img src={Slide2} alt="Slide 1" style={imageStyle} />
          </div>
          <div>
            <img src={Slide3} alt="Slide 2" style={imageStyle} />
          </div>
          <div>
            <img src={Slide1} alt="Slide 3" style={imageStyle} />
          </div>
          <div>
            <img src={Slide4} alt="Slide 4" style={imageStyle} />
          </div>
          <div>
            <img src={Slide5} alt="Slide 5" style={imageStyle} />
          </div>
        </Slider>
      </div>

      <div className="our-story-container">
        <h1 className="head1">Our Story</h1>
        <p className="para1">
          Welcome to our story page! Here, you can learn about our journey and how we came to be.
          From humble beginnings to our current success, we have always strived to deliver the best
          products and services to our customers. We hope you enjoy reading about our story as much
          as we enjoyed living it onClick.
        </p>
      </div>

      <div>
        <h1 className="main-heading">Quality Furniture for Modern Living</h1>
        <div className="card-container">
          {cards.map((card, index) => (
            <div className="card" key={index}>
              <img src={card.image} alt={card.title} className="card-image" />
              <h3 className="card-title">{card.title}</h3>
              <p className="card-description">{card.description}</p>
            </div>
          ))}
        </div>
      </div>




      <div className="responsive-page">
        <h1 className="heading"> Inspiring Interior Design Solutions</h1>
        <div className="image-grid">
          {images.map((image, index) => (
            <div className="image-container" key={index}>
              <img src={image.src} alt={image.text} />
              <div className="overlay">
                <div className="text">{image.text}</div>
              </div>
            </div>
          ))}
        </div>
        <Link to="/Portfolio">
            <button className="center-button">Click Me</button>
        </Link>
      </div>



      <div className="home-interior-design">
        <h1 className="heading">OUR HOME INTERIOR DESIGN SERVICE</h1>
        <div className="services-grid">
          {services.map((service, index) => (
            <div className="service-item" key={index}>
              <div className="icon">{service.icon}</div>
              <div className="name">{service.name}</div>
            </div>
          ))}
        </div>
      </div>



      <div className="professional-expertise-container">
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <h2>Contact Us</h2>
            <div>
              <label>Name:</label>
              <input type="text" name="name" required />
            </div>
            <div>
              <label>Email:</label>
              <input type="email" name="email" required />
            </div>
            <div>
              <label>Phone Number:</label>
              <input type="tel" name="phone" required />
            </div>
            
            <div>
              <label>Message:</label>
              <textarea name="message" required></textarea>
            </div>
            
            <button type="submit">Submit</button>
            {formSubmitted && <p className="success-message">Successfully Submitted</p>}
          </form>
        </div>
        <div className="info-container">
          <h1>400+ Expert Professionals</h1>
          <h2>10+ Years of Expertise</h2>
          <h2>8250+ Happy Home Deliveries</h2>
        </div>
      </div>


      <div className="reviews-section">
        <h2 className="reviews-heading">Customer Reviews</h2>
        <div className="reviews-scroller">
          {reviews.map((review, index) => (
            <div className="review-card" key={index}>
              <img src={review.image} alt={review.name} className="review-image" />
              <h3 className="review-name">{review.name}</h3>
              <p className="review-message">{review.message}</p>
              <div className="review-stars">
                {'★'.repeat(review.stars)}{'☆'.repeat(5 - review.stars)}
              </div>
            </div>
          ))}
        </div>
      </div>


      <div className="home">
        <div className="shop-section">
          <div className="carousel-container">
            <Slider {...settings}>
              <div>
                <img src={Home1} alt="Slide 1" style={imageStyle} />
              </div>
              <div>
                <img src={Home2} alt="Slide 2" style={imageStyle} />
              </div>
              <div>
                <img src={Home3} alt="Slide 3" style={imageStyle} />
              </div>
            </Slider>
          </div>
          <div className="address-container">
            <h2>Address</h2>
            <p>Vivek Furniture Shop, s no 121/15/3 vivek Furniture near railway line, godbole, Mhasoba Wasti, Manjari Budruk, Pune, Maharashtra 412307</p>
            <p>Landmark: Near railway line</p>
            <p>Phone Number: 9890114558, 9096839148, 9022612566</p>
            <p>Timing: Mon-Sun 10:00 AM to 7:30 PM</p>
            <Link to="https://www.google.com/maps/dir//s+no+121%2F15%2F3+vivek+Furniture+near+railway+line,+godbole,+Mhasoba+Wasti,+Manjari+Budruk,+Pune,+Maharashtra+412307/@18.5184755,73.8768496,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2c1374d3a3b3f:0x6fbdb9148d1c8b35!2m2!1d73.9592513!2d18.5184933?entry=ttu">
              <button className="direction-button">Get Direction</button>
            </Link>
          </div>
        </div>
      </div>





      <div className="faq-container">
        <h1 className="main-heading">Frequently Asked Questions</h1>
        <h2 className="faq-heading">Our FAQ Section</h2>
        {faqs.map((faq, index) => (
          <div
            className={`faq-item ${index === activeIndex ? 'active' : ''}`}
            key={index}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              <span>{faq.question}</span>
              <div className="arrow">{index === activeIndex ? '▲' : '▼'}</div>
            </div>
            <div className="faq-answer">{faq.answer}</div>
          </div>
        ))}
      </div>

    </div>
  );
};

export default Home;



