
import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Footer.css';



import calllogo from './footer-image/Calllogo.png'
import whatsapp from './footer-image/Whatsapplogo.png'

const Footer = () => { 





  const handleWhatsAppClick = () => {
    // Replace `1234567890` with the recipient's phone number
    const phoneNumber = '+91-9096839148';
    // Replace `Hello%20there!` with the message you want to send
    const message = 'Hello%20there!';
    // Construct the WhatsApp link
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;
    // Open the WhatsApp link in a new tab
    window.open(whatsappLink, '_blank');
  };







  return (


    <>



<div>
     <div className="logo-container">
   <a href="tel:91-9096839148"><img src={calllogo} alt='callme' className="logo"/></a>
 

</div>
 
 <div className="logo-conta">
   
        <img
       src={whatsapp}
        alt="WhatsApp Logo"
        
        className="logo"
        onClick={handleWhatsAppClick}
        />

      
      </div>

</div>



















      <footer className="footer">
        <div className="footer-container">
          <div className="footer-section">
            <h4>Company</h4>
            <ul>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/portfolio">Portfolio</Link></li>
              <li><Link to="/client">Client</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </div>

          <div className="footer-section">
            <h4>Follow Us</h4>
            <ul className="social-icons">
              <li><Link to="https://facebook.com"><FaFacebook /> Facebook</Link></li>
              <li><Link to="https://twitter.com"><FaTwitter /> Twitter</Link></li>
              <li><Link to="https://instagram.com"><FaInstagram /> Instagram</Link></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Address</h4>
            <p>Sr. No 121/15/3, near railway line, godbole wasti, Manjari bk,Pune 412307</p>
          </div>
          <div className="footer-section">
            <h4>Contact</h4>
            <p>+91-9890114558</p>
            <p>+91-9096839148</p>
            <p>+91-9022612566</p>
          </div>
          <div className="footer-section">
            <h4>Email</h4>
            <p> artisansinterior014@gmail.com</p>
          </div>
        </div> 
      </footer>

      <div className="footer-section2">
        <p>© 2024 - Privacy | Designed by KA IT Software Solutions LLP</p>
      </div>
    </>
  );
};

export default Footer;


