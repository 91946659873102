import React from 'react';
import './Client.css';
import review1 from '../Home/Home-image/review1.jpg';
import review2 from '../Home/Home-image/review2.jpeg';
import review3 from '../Home/Home-image/review3.webp';
import review4 from '../Home/Home-image/review4.webp';
import review5 from '../Home/Home-image/review5.jpg';
import review6 from '../Home/Home-image/review6.jpg';

const Client = () => {
  const clients = [
    {
      name: 'Tushar shelake',
      photo: review1,
      rating: '★★★★★',
      description: 'We are happy with your services!',
    },
    {
      name: 'Ganesh Nimbalkar',
      photo: review2,
      rating: '★★★★☆',
      description: 'Excellent work, very satisfied!',
    },
    {
      name: 'Hanumant Hargude',
      photo: review3,
      rating: '★★★★★',
      description: 'Great job, highly recommend!',
    },
    {
      name: 'Suvarna Sasvade',
      photo: review4,
      rating: '★★★★☆',
      description: 'Very professional and timely!',
    },
    {
      name: 'Vinayak Khair',
      photo: review5,
      rating: '★★★★★',
      description: 'Outstanding service, thank you!',
    },
    {
      name: 'Rahul Mane',
      photo: review6,
      rating: '★★★★★',
      description: 'Wonderful experience!',
    },
  ];

  return (
    <div className="happy-clients-container1">
      <h1 className="heading1">Our Happy Clients!</h1>
      <div className="cards-container1">
        {clients.map((client, index) => (
          <div key={index} className="card1">
            <img src={client.photo} alt={client.name} className="client-photo" />
            <div className="client-info">
              <h2 className="client-name">{client.name}</h2>
              <p className="client-rating">{client.rating}</p>
              <p className="client-description">{client.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Client;
