import React from 'react';
import Image from './About-image/f1.jpg';
import Image2 from './About-image/f2.jpg';
import Image3 from './About-image/f3.jpg';
import Image4 from './About-image/f4.jpg';
import './About.css';

const About = () => {
  return (
    <>
      <div className="about-heading">
        <h1>Artisans Interior</h1>
        {/* <h2 className="shop-name">Artisans Interior</h2> */}
      </div>
      <div className='main'>
        <div className='about'>
          <div className='grid'>
            <div className='item'>
              <img src={Image} alt="f1" />
            </div>
            <div className='item'>
              <img src={Image2} alt="f2" />
            </div>
            <div className='item'>
              <img src={Image3} alt="f3" />
            </div>
            <div className='item'>
              <img src={Image4} alt="f4" />
            </div>
          </div>
        </div>
        <div className='about-info'>
          <p>Decorpot is a leading and people’s favorite home interior brand which was founded in 2015 with an aim of creating premium and luxury end-to-end home interiors at fair prices and delivering the dreams of the clients. With 8250+ happy homes delivered, our team of 400+ design experts help us bring life to dream home interiors across 8 different cities Bangalore, Hyderabad, Chennai, Coimbatore, Pune, Noida, Kolkata and Thane with 14 exclusive world-class experience centers that showcase the world’s best design styles and practical interiors like which we create and deliver for your home spaces.<br/></p>
          <p> Decorpot offers End-to-End home interior solutions from designing living room interiors, kitchen interiors, bedroom interiors, foyers, tv units, balconies, bar units, wardrobes, and false ceilings with the help of 2D designs and 3D models to manufacturing at premium quality and installing them with perfection. We are making waves in the interior design industry with our creative and out-of-the-box designs that are inspiring and voguish.<br/></p>
          <p>  Having been known for delivering premium home interiors at affordable and fair prices, Decorpot is preferred by people for our customer-centric approach and on-time delivery. We offer a 10-year warranty for home interiors and have our own manufacturing unit with high-end equipments to make top-quality materials.<br/></p>
          <p> Recently, Decorpot is recognized as the “LEADING & MOST PROMINENT INTERIOR DESIGN FIRM OF THE YEAR 2023, SOUTH INDIA” at National Architecture & Interior Design Excellence Awards & Conference 2023 for our splendid work in the interior designing industry. With a team of highly creative and experienced professionals, Decorpot is leaving a great footprint in terms of quality and designs which satisfy the clients. Decorpot is committed to the satisfaction of customers and to making their dream home space meaningful.</p>
        </div>
      </div>
    </>
  );
}

export default About;
