import React from "react";
import "./Contact.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';


const Contact = () => {
  return (
    <>
      <div className="container">
        <div className="contact-form">
          <h2>Send Us a Message</h2>
          <form>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" placeholder="Your Name" />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email Id</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your Email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="comments">Comments</label>
              <textarea
                id="comments"
                name="comments"
                placeholder="Enter Your Message"
              ></textarea>
            </div>
            <button type="submit">Submit Now</button>
          </form>
        </div>



      </div>


      <div className="contact-card">
        <div className="contact-logo">
          <FontAwesomeIcon icon={faPhone} />
        </div>

        <h3>Contact</h3>
        <p>+91-9890114558</p>
        <p>+91-9096839148</p>
        <p>+91-9022612566</p>
      </div>


      <div className="address-card">
        <div className="address-logo">
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </div>
        <h3>Address</h3>
        <p>
          <a
            href="https://maps.app.goo.gl/Ciix95ERtfsdFLrR6"
            target="_blank"
            rel="noopener noreferrer"
          >

          </a>
        </p>

        <p>

        Sr. No 121/15/3, near railway line, godbole wasti, Manjari bk,Pune 412307

        </p>
      </div>



      <div className="email-card">
        <div className="email-logo">
          <FontAwesomeIcon icon={faEnvelope} />
        </div>
        <h3> Email Id</h3>
        <p>artisansinterior014@gmail.com</p>
      </div>

      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d121047.71505086437!2d73.8391125162299!3d18.540598386400607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d18.5492846!2d73.947738!4m5!1s0x3bc2c1374d3a3b3f%3A0x6fbdb9148d1c8b35!2svivek%20furniture!3m2!1d18.5184933!2d73.95925129999999!5e0!3m2!1sen!2sin!4v1721039253585!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default Contact;