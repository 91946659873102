import React from 'react';
import './Services.css';
import furniture1 from './Services-img/furniture1.jpg';
import furniture2 from './Services-img/furniture2.jpg';
import furniture3 from './Services-img/furniture3.jpg';
import lighting1 from './Services-img/lighting1.jpg';
import lighting2 from './Services-img/lighting2.jpg';
import lighting3 from './Services-img/lighting3.jpg';
import painting1 from './Services-img/painting1.jpg';
import painting2 from './Services-img/painting2.png';
import painting3 from './Services-img/painting3.jpg';
import flooring1 from './Services-img/flooring1.jpg';
import flooring2 from './Services-img/flooring2.webp';
import flooring3 from './Services-img/flooring3.jpg';
import ceiling1 from './Services-img/Ceiling1.webp';
import ceiling2 from './Services-img/Ceiling2.webp';
import ceiling3 from './Services-img/Ceiling3.jpg';
import fabrication1 from './Services-img/fabrication1.webp';
import fabrication2 from './Services-img/fabrication2.webp';
import fabrication3 from './Services-img/fabrication3.webp';
import civil1 from './Services-img/civil1.jpg';
import civil2 from './Services-img/civil2.webp';
import civil3 from './Services-img/civil3.jpg';

const Services = () => {
  return (
    <div className="services-container">
      <h1>Our Services</h1>

      <div className="service-section">
        <h2>Furniture</h2>
        <div className="images">
          <img src={furniture1} alt="Furniture 1" />
          <img src={furniture2} alt="Furniture 2" />
          <img src={furniture3} alt="Furniture 3" />
        </div>
      </div>

      <div className="service-section">
        <h2>Lighting</h2>
        <div className="images">
          <img src={lighting1} alt="Lighting 1" />
          <img src={lighting2} alt="Lighting 2" />
          <img src={lighting3} alt="Lighting 3" />
        </div>
      </div>

      <div className="service-section">
        <h2>Painting</h2>
        <div className="images">
          <img src={painting1} alt="Painting 1" />
          <img src={painting2} alt="Painting 2" />
          <img src={painting3} alt="Painting 3" />
        </div>
      </div>

      <div className="service-section">
        <h2>Flooring</h2>
        <div className="images">
          <img src={flooring1} alt="Flooring 1" />
          <img src={flooring2} alt="Flooring 2" />
          <img src={flooring3} alt="Flooring 3" />
        </div>
      </div>

      <div className="service-section">
        <h2>False Ceiling</h2>
        <div className="images">
          <img src={ceiling1} alt="Ceiling 1" />
          <img src={ceiling2} alt="Ceiling 2" />
          <img src={ceiling3} alt="Ceiling 3" />
        </div>
      </div>

      <div className="service-section">
        <h2>Fabrication</h2>
        <div className="images">
          <img src={fabrication1} alt="Fabrication 1" />
          <img src={fabrication2} alt="Fabrication 2" />
          <img src={fabrication3} alt="Fabrication 3" />
        </div>
      </div>

      <div className="service-section">
        <h2>Civil Work</h2>
        <div className="images">
          <img src={civil1} alt="Civil Work 1" />
          <img src={civil2} alt="Civil Work 2" />
          <img src={civil3} alt="Civil Work 3" />
        </div>
      </div>

      <div className="guarantee">
        <h2>Guarantee of Work</h2>
        <p>We guarantee our work for 6 months.</p>
      </div>
    </div>
  );
};

export default Services;
